import React from 'react'
import NextLink from 'next/link'
import { Button as ChakraButton, ThemingProps } from '@chakra-ui/react'
import { FunctionComponentWithChildren } from '@alexis/frontend/helpers/componentHelpers'
import { UrlObject } from 'url'
import { HTMLChakraProps } from '@chakra-ui/system'
import { ButtonOptions } from '@chakra-ui/button'

const ButtonLink: FunctionComponentWithChildren<
  HTMLChakraProps<'button'> &
    ButtonOptions &
    ThemingProps<'Button'> & {
      target?: string
      href: string | UrlObject
    }
> = ({ children, href, target, ...props }) => (
  <NextLink href={href} target={target} passHref>
    <ChakraButton as="a" target={target} {...(props as ThemingProps<'Button'>)}>
      {children}
    </ChakraButton>
  </NextLink>
)

export default ButtonLink
