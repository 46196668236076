import React, { ComponentProps } from 'react'
import NextLink from 'next/link'
import { Link as ChakraLink, ThemingProps } from '@chakra-ui/react'
import { FunctionComponentWithChildren } from '@alexis/frontend/helpers/componentHelpers'

const Link: FunctionComponentWithChildren<
  ComponentProps<typeof NextLink> & ComponentProps<typeof ChakraLink>
> = ({ children, isExternal, href, ...linkProps }) => (
  <NextLink href={href} passHref {...linkProps}>
    <ChakraLink
      isExternal={isExternal}
      {...(linkProps as ThemingProps<'Link'>)}
    >
      {children}
    </ChakraLink>
  </NextLink>
)

export default Link
