import * as React from 'react'
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
} from '@chakra-ui/react'
import { HomeProps } from '@alexis/frontend/pages'
import {
  getImageUrl,
  getLocalizedString,
} from '@alexis/frontend/sanity/sanityClient'
import Link from '../ui/Link'
import {
  faAmazon,
  faApple,
  faDeezer,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons'
import FaIcon from '@alexis/frontend/icon/FaIcon'
import { music } from '@alexis/frontend/navigation'
import ButtonLink from '@alexis/frontend/components/ui/ButtonLink'

type AlbumsSectionAlbum = HomeProps['albums'][0]

const ListenToAlbumLinks = ({
  links: { appleMusic, spotify, amazonMusic, deezer },
}: {
  links: AlbumsSectionAlbum['links']
}) => {
  return (
    <Flex>
      {appleMusic ? (
        <Link isExternal href={appleMusic} p={1}>
          <FaIcon icon={faApple} />
        </Link>
      ) : null}
      {spotify ? (
        <Link isExternal href={spotify} py={1} px={2}>
          <FaIcon icon={faSpotify} />
        </Link>
      ) : null}
      {amazonMusic ? (
        <Link isExternal href={amazonMusic} py={1} px={2}>
          <FaIcon icon={faAmazon} />
        </Link>
      ) : null}
      {deezer ? (
        <Link isExternal href={deezer} py={1} px={2}>
          <FaIcon icon={faDeezer} />
        </Link>
      ) : null}
    </Flex>
  )
}

const Album = ({
  album: { title, image, links },
  locale,
}: {
  album: AlbumsSectionAlbum
  locale: string
}) => {
  return (
    <Flex direction="column" align="center" mt={16}>
      <Image
        mt={4}
        w={320}
        borderRadius="md"
        boxShadow="md"
        src={getImageUrl(image).url()}
        alt={title}
      />

      <Flex direction="column" mt={4}>
        {/*<Text>{releaseDate.substring(0, 4)} Todo release info label...</Text>*/}
        {/*<Text maxW={['none', 'none', 600]} textAlign="justify">*/}
        {/*  {description}*/}
        {/*</Text>*/}
        <Box flex={1} />
        {!!links.preorder && (
          <ButtonLink variant="outline" target="_blank" href={links.preorder}>
            {getLocalizedString(
              { en: 'Preorder', fr: 'Précommander', _type: 'localeString' },
              locale,
            )}
          </ButtonLink>
        )}
        <ListenToAlbumLinks links={links} />
      </Flex>
    </Flex>
  )
}

const AlbumsSection = ({ albums, locale }: HomeProps) => {
  const gridPaddingExceptTop = [1, 4, 16]

  return (
    <Container maxW="container.lg">
      <Heading pt={32}>{music.label[locale]}</Heading>
      <SimpleGrid
        columns={[1, 2]}
        mt={4}
        pr={gridPaddingExceptTop}
        pb={gridPaddingExceptTop}
        pl={gridPaddingExceptTop}
        spacing={4}
      >
        {albums.map((album) => (
          <Album album={album} key={album._id} locale={locale} />
        ))}
      </SimpleGrid>
    </Container>
  )
}

export default AlbumsSection
