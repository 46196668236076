import React from 'react'
import { Container, Flex, Heading, Text } from '@chakra-ui/react'
import * as Scroll from 'react-scroll'
import { HomeProps } from '@alexis/frontend/pages'
import { getLocalizedString } from '@alexis/frontend/sanity/sanityClient'
import FaIcon from '@alexis/frontend/icon/FaIcon'
import { findSocialIconForUrl } from '@alexis/frontend/icon/findIconForUrl'
import Link from '@alexis/frontend/components/ui/Link'
import {
  about,
  navLinks,
  useScrollNavigationHeaderOffset,
} from '@alexis/frontend/navigation'

const scrollDuration = (scrollDistance: number) => {
  const baseDuration = Math.abs(scrollDistance) / 2
  const min = 200
  const max = 500

  return Math.max(Math.min(baseDuration, max), min)
}

const Header = ({ info: { headerLinks }, locale }: HomeProps) => {
  const offset = useScrollNavigationHeaderOffset()

  return (
    <Flex
      as="header"
      position="fixed"
      backgroundColor="brand.100"
      left="0"
      height={[24, 24, 32]}
      width="100%"
      zIndex="1000"
    >
      <Container
        h="100%"
        flex={1}
        centerContent
        flexDirection={['column', 'column', 'row']}
        maxW="container.xl"
      >
        <Heading
          as={Scroll.Link}
          href="/"
          smooth
          spy
          to={about.to}
          size={['xl', '2xl']}
          duration={scrollDuration}
          ml={[0, 0, 8]}
          mt={[2, 4, 0]}
          offset={offset}
          wordBreak="break-word"
          _hover={{ textDecoration: 'none' }}
        >
          Alexis Moutzouris
        </Heading>
        <Flex
          flex={1}
          align="center"
          pr={[0, 0, 4]}
          justify={['center', 'flex-end']}
          flexWrap={['wrap', 'wrap', 'nowrap']}
        >
          {navLinks.map(({ to, label }, index) => (
            <Text
              key={index}
              as={Scroll.Link}
              mx={[2, 4]}
              fontSize={['lg', 'xl']}
              fontFamily="heading"
              smooth
              spy
              duration={scrollDuration}
              _hover={{ textDecoration: 'underline' }}
              offset={index === 0 ? offset : 0}
              to={to}
              cursor="pointer"
            >
              {label[locale]}
            </Text>
          ))}

          {headerLinks.map((link) => {
            const iconLookup = findSocialIconForUrl(link.url)
            const title = getLocalizedString(link.title)

            return (
              <Link
                key={link.url}
                mx={[2, 4]}
                fontSize={['md', 'lg']}
                isExternal={true}
                href={link.url}
                aria-label={title}
              >
                {iconLookup ? (
                  <FaIcon icon={iconLookup} />
                ) : (
                  getLocalizedString(link.title, locale)
                )}
              </Link>
            )
          })}
        </Flex>
      </Container>
    </Flex>
  )
}

export default Header
