import { createClient, SanityDocument } from 'sanity-codegen'
import { PublicConfig } from '@alexis/frontend/environment'
import {
  Documents,
  LocaleString,
  LocaleText,
  RequiredLocaleString,
  RequiredLocaleText,
} from '@alexis/sanity/schemaTypes'
import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@sanity/client'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'

const { dataset, token, useCdn, projectId } = PublicConfig.Sanity

export const rawClient = sanityClient({
  projectId,
  dataset,
  apiVersion: '2022-08-16',
  token,
  useCdn,
})

export const client = createClient<Documents>({
  projectId,
  dataset,
  fetch: typeof window === 'undefined' ? global.fetch : window.fetch,
  apiVersion: '2022-08-16',
  token,
  useCdn,
})

const builder = imageUrlBuilder(rawClient)

export const getImageUrl = (image: SanityImageSource) => builder.image(image)

export const getLocalizedString = (
  {
    fr,
    en,
    _type,
  }: LocaleString | RequiredLocaleString | LocaleText | RequiredLocaleText,
  locale?: string,
): string => {
  if (locale === 'fr') {
    return fr ?? ''
  }
  return en ?? ''
}

type LocalizedDocument<T extends SanityDocument> = {
  [key in keyof T]: T[key] extends
    | LocaleString
    | RequiredLocaleString
    | LocaleText
    | RequiredLocaleText
    | undefined
    ? string
    : T[key]
}

export const localizeDocument = <T extends SanityDocument>(
  document: T,
  locale?: string,
): LocalizedDocument<T> => {
  const localized = Object.fromEntries(
    Object.entries(document).map(([key, value]) => [
      key,
      value._type === 'localeString' ||
      value._type === 'localeText' ||
      value._type === 'requiredLocaleString' ||
      value._type === 'requiredLocaleText'
        ? getLocalizedString(value, locale)
        : value,
    ]),
  ) as LocalizedDocument<T>

  return localized
}
