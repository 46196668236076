import { Box, Image } from '@chakra-ui/react'
import * as React from 'react'
import { FunctionComponentWithChildren } from '@alexis/frontend/helpers/componentHelpers'

export const Frame: FunctionComponentWithChildren = ({ children }) => {
  return (
    <Box
      position="relative"
      p={3}
      my={6}
      h={[300, 300, 400, 480]}
      w={['100%', 400, 534, 640]}
    >
      <Image
        src="/images/frame2.svg"
        position="absolute"
        alt="Ornate frame illustration for youtube video"
        top="0"
        pointerEvents="none"
        transform="scale(1.5)"
        left="0"
        w="100%"
        h="100%"
      />
      {children}
    </Box>
  )
}
