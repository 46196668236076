import * as React from 'react'
import { Container, Flex, Heading, Text } from '@chakra-ui/react'
import { HomeProps } from '@alexis/frontend/pages'
import ButtonLink from '@alexis/frontend/components/ui/ButtonLink'
import { contact } from '@alexis/frontend/navigation'
import { Frame } from '@alexis/frontend/components/ui/Frame'
import nl2br from 'react-nl2br'

const ContactSection = ({
  locale,
  info: { contactInfo, contactButtonText },
}: HomeProps) => {
  return (
    <Container maxW="container.lg" mt={16} alignItems="center" mb={8}>
      <Flex mt={8} w="100%" align="center" justify="center">
        <Frame>
          <Flex
            direction="column"
            maxW={500}
            h="100%"
            pb="20%"
            mx="auto"
            justifyContent="center"
            align="center"
            mt={[4, 6, 12]}
          >
            <Heading>{contact.label[locale]}</Heading>
            <Text mt={8} textAlign="center">
              {nl2br(contactInfo)}
            </Text>
            <ButtonLink
              mt={8}
              variant="outline"
              href="mailto:alexismoutzouris@gmail.com"
              target="_blank"
            >
              {contactButtonText}
            </ButtonLink>
          </Flex>
        </Frame>
      </Flex>
    </Container>
  )
}

export default ContactSection
