import * as React from 'react'
import { Container, Flex, Image, Text } from '@chakra-ui/react'
import { HomeProps } from '@alexis/frontend/pages'
import nl2br from 'react-nl2br'
import { getImageUrl } from '@alexis/frontend/sanity/sanityClient'
import { YoutubeVideoDisplay } from '@alexis/frontend/components/ui/YoutubeVideoDisplay'

const HeroSection = ({ info, locale }: HomeProps) => {
  return (
    <>
      <Flex
        justify="center"
        align="center"
        mx="auto"
        position="relative"
        bgImage='url("/images/stars-bg.png")'
        bgRepeat="no-repeat"
        bgPosition="center"
        bgSize="cover"
      >
        <Container maxW="container.xl" px={8}>
          <YoutubeVideoDisplay artwork={info.introVideo} locale={locale} />
        </Container>
      </Flex>
      <Container maxW="container.xl">
        <Flex
          mt={8}
          direction={['column', 'column', 'row']}
          align="center"
          justify="space-evenly"
        >
          <Flex direction="column" maxW={600} p={8}>
            <Text fontSize="lg" textAlign="justify">
              <br />
              {nl2br(info.bio)}
            </Text>
          </Flex>
          <Image
            src={getImageUrl(info.portrait).url()}
            alt="Alexis Moutzouris"
            borderRadius="md"
            maxW={['100%', '50%']}
            maxH={600}
          />
        </Flex>
      </Container>
    </>
  )
}

export default HeroSection
