// Return the size of the element parent to the garget
import { RefObject, useEffect } from 'react'
import * as React from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useContainerSize = (target: RefObject<HTMLElement>) => {
  const [size, setSize] = React.useState<DOMRect>()

  useEffect(() => {
    if (!target.current?.parentElement) {
      return
    }
    setSize(target.current.parentElement.getBoundingClientRect())
  }, [target])

  useResizeObserver(target.current?.parentElement ?? null, (entry) =>
    setSize(entry.contentRect),
  )

  return size
}
