import { chakra, ChakraProps } from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'

const FaIcon = ({ icon, ...props }: ChakraProps & FontAwesomeIconProps) => {
  const Icon = chakra(FontAwesomeIcon)

  return <Icon icon={icon} {...props} />
}

export default FaIcon
