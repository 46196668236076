import * as React from 'react'
import {
  Box,
  Container,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { HomeProps, Locale } from '@alexis/frontend/pages'
import ButtonLink from '@alexis/frontend/components/ui/ButtonLink'
import { format } from 'date-fns'
import { enUS, fr } from 'date-fns/locale'
import { tour } from '@alexis/frontend/navigation'

const dateFnsLocales = { fr, en: enUS }

type TourSectionShow = HomeProps['shows'][0]

const showLinkWording = {
  en: 'Tickets and info',
  fr: 'Billets et infos',
}

const ShowDate = ({
  date: dateString,
  locale,
}: {
  date: string
  locale: Locale
}) => {
  const date = new Date(dateString)
  const dateFnsFormatOptions = { locale: dateFnsLocales[locale] }

  const day = format(date, 'dd', dateFnsFormatOptions)
  const monthLowercase = format(date, 'LLL', dateFnsFormatOptions)
  const month = monthLowercase[0].toUpperCase() + monthLowercase.slice(1)
  const year = date.getFullYear()

  const displayDayBeforeMonth = locale === 'fr'

  return (
    <Flex direction="column" align="center">
      <Text mt={0} fontSize={displayDayBeforeMonth ? 'xl' : 'md'}>
        {displayDayBeforeMonth ? day : month}
      </Text>
      <Text mt={1} fontSize={displayDayBeforeMonth ? 'xl' : '2xl'}>
        {displayDayBeforeMonth ? month : day}
      </Text>
      <Text mt={1} fontSize="sm">
        {year}
      </Text>
    </Flex>
  )
}

const ShowRow = ({
  show: { date, project, venue, eventLink },
  locale,
}: {
  show: TourSectionShow
  locale: Locale
}) => {
  const linkToEvent = eventLink ? (
    <ButtonLink variant="outline" href={eventLink} target="_blank">
      {showLinkWording[locale]}
    </ButtonLink>
  ) : null

  return (
    <Tr>
      <Td px={[2, 4, 6]}>
        <ShowDate date={date} locale={locale} />
      </Td>
      <Td px={[2, 4, 6]}>
        <Box>
          <Heading fontSize="xl">{project}</Heading>
          <Text mt={2}>{venue}</Text>
        </Box>
        {!!linkToEvent && (
          <Box display={['box', 'box', 'none']} w="100%" pt={3}>
            {linkToEvent}
          </Box>
        )}
      </Td>
      <Td display={['none', 'none', 'table-cell']}>{linkToEvent}</Td>
    </Tr>
  )
}

const TourSection = ({ shows, locale }: HomeProps) => {
  return (
    <Container maxW="container.lg">
      <Heading pt={32}>{tour.label[locale]}</Heading>
      <Table mt={8} variant="simple">
        <Tbody>
          {shows.map((show) => (
            <ShowRow show={show} key={show._id} locale={locale} />
          ))}
        </Tbody>
      </Table>
    </Container>
  )
}

export default TourSection
