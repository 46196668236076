import { YoutubeVideo } from '@alexis/sanity/schemaTypes'
import * as React from 'react'
import { useRef } from 'react'
import getYouTubeId from 'get-youtube-id'
import * as Sentry from '@sentry/nextjs'
import { getImageUrl } from '@alexis/frontend/sanity/sanityClient'
import { useContainerSize } from '@alexis/frontend/hooks/useContainerSize'
import ReactPlayer from 'react-player/youtube'

export const YoutubeVideoDisplay = ({
  artwork,
}: {
  artwork: YoutubeVideo
  locale: string
}) => {
  const ref = useRef<HTMLElement>(null)
  const size = useContainerSize(ref)
  const id = getYouTubeId(artwork.url)

  const refSpan = <span ref={ref}></span>

  if (!id) {
    Sentry.captureException(
      new Error('Invalid youtube video for project artwork'),
      {
        extra: {
          youtubeVideoDocumentId: artwork._id,
          youtubeVideoTitle: artwork.title.en,
          youtubeVideoUrl: artwork.url,
        },
      },
    )
    return refSpan
  }

  if (!size) {
    return refSpan
  }

  const width = size.width
  const height = width / 2
  const src = `https://www.youtube-nocookie.com/embed/${id}`
  const previewImage = artwork.previewImage
    ? getImageUrl(artwork.previewImage).width(1216).url()
    : false

  return (
    <>
      {refSpan}
      <ReactPlayer
        height={height}
        width={width}
        playing={true}
        controls
        url={src}
        light={previewImage}
      />
    </>
  )
}
