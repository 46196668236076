import { useBreakpointValue } from '@chakra-ui/react'

type NavLink = {
  label: {
    en: string
    fr: string
  }
  to: string
}

export const about: NavLink = {
  label: {
    en: 'About',
    fr: 'Info',
  },
  to: 'about',
}

export const music: NavLink = {
  label: {
    en: 'Music',
    fr: 'Musique',
  },
  to: 'music',
}

export const tour: NavLink = {
  label: {
    en: 'Tour',
    fr: 'Concerts',
  },
  to: 'tour',
}

export const contact: NavLink = {
  label: {
    en: 'Contact',
    fr: 'Contact',
  },
  to: 'contact',
}

export const useScrollNavigationHeaderOffset = () => {
  return useBreakpointValue({ base: -96, md: -128 }, { ssr: false })
}

export const navLinks: NavLink[] = [about, music, tour, contact]
