import type {
  GetStaticPropsContext,
  InferGetStaticPropsType,
  NextPage,
} from 'next'
import HeroSection from '@alexis/frontend/components/home/HeroSection'
import * as React from 'react'
import { getInfo } from '@alexis/frontend/sanity/info'
import AlbumsSection from '@alexis/frontend/components/home/AlbumsSection'
import PageLayout from '@alexis/frontend/layout/PageLayout'
import * as Scroll from 'react-scroll'
import { getAlbums } from '@alexis/frontend/sanity/albums'
import { about, contact, music, tour } from '@alexis/frontend/navigation'
import { getShows } from '@alexis/frontend/sanity/shows'
import TourSection from '@alexis/frontend/components/home/TourSection'
import ContactSection from '@alexis/frontend/components/home/ContactSection'

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  const [info, albums, shows] = await Promise.all([
    getInfo(locale),
    getAlbums(locale),
    getShows(locale),
  ])

  return {
    props: { info, albums, shows, locale: (locale ?? 'en') as 'fr' | 'en' },
  }
}

export type HomeProps = InferGetStaticPropsType<typeof getStaticProps>
export type Locale = HomeProps['locale']

const Home: NextPage<HomeProps> = (props) => (
  <PageLayout htmlTitle={'Alexis Moutzouris'} {...props}>
    <Scroll.Element name={about.to}>
      <HeroSection {...props} />
    </Scroll.Element>

    <Scroll.Element name={music.to}>
      <AlbumsSection {...props} />
    </Scroll.Element>

    <Scroll.Element name={tour.to}>
      <TourSection {...props} />
    </Scroll.Element>
    <Scroll.Element name={contact.to}>
      <ContactSection {...props} />
    </Scroll.Element>
  </PageLayout>
)

export default Home
