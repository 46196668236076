import React from 'react'
import { useRouter } from 'next/router'
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import Link from '@alexis/frontend/components/ui/Link'
import { HomeProps } from '@alexis/frontend/pages'
import * as Scroll from 'react-scroll'
import {
  about,
  useScrollNavigationHeaderOffset,
} from '@alexis/frontend/navigation'

const LangageSwitcher = ({
  locale,
  currentLocale,
  changeLocale,
}: {
  locale: string
  currentLocale: string
  changeLocale: (nextLocale: string) => void
}) => {
  if (currentLocale === locale) {
    return (
      <Button disabled pointerEvents="none" variant="link">
        {locale.toUpperCase()}
      </Button>
    )
  }

  return (
    <Button variant="link" onClick={() => changeLocale(locale)}>
      {locale.toUpperCase()}
    </Button>
  )
}

const Footer = ({ info: { cookiesDisclaimer, legal } }: HomeProps) => {
  const router = useRouter()
  const { pathname, asPath, query, locale } = router
  const spacer = ' - '
  const displayLegalLink = pathname !== '/legal'
  const changeLocale = (nextLocale: string) => {
    router.push({ pathname, query }, asPath, { locale: nextLocale })
  }

  const isIndexPage = router.pathname === '/'

  const offset = useScrollNavigationHeaderOffset()
  return (
    <Flex
      padding={8}
      align="center"
      justify="center"
      width="100%"
      fontSize="sm"
    >
      <Flex as="nav" direction="column" align="center" justify="center">
        <Heading
          as={isIndexPage ? Scroll.Link : Link}
          size="md"
          href="/"
          smooth
          spy
          offset={offset}
          to={about.to}
          _hover={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Alexis Moutzouris
        </Heading>
        <Box mt={4}>
          <LangageSwitcher
            changeLocale={changeLocale}
            currentLocale={locale ?? 'en'}
            locale={'en'}
          />
          <LangageSwitcher
            changeLocale={changeLocale}
            currentLocale={locale ?? 'en'}
            locale={'fr'}
          />
        </Box>
        <Text size="xs" mt={4}>
          © Alexis Moutzouris 2022
          {displayLegalLink ? (
            <span>
              {spacer}
              <Link href="/legal">{legal}</Link>
            </span>
          ) : null}
        </Text>
        <Text size="xs" mt={4}>
          {cookiesDisclaimer}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Footer
