import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const findSocialIconForUrl = (url: string): IconProp | null => {
  if (url.includes('instagram')) {
    return ['fab', 'instagram']
  }
  if (url.includes('youtube')) {
    return ['fab', 'youtube']
  }

  return null
}
